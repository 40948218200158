import React from 'react';

const Title = ({ className, text, isH1 = false }) => {

  return (
    <section className={ `dse-title ${className || ''}` }>
      {isH1 ? 
        <h1 className='dse-title__text' dangerouslySetInnerHTML={{__html: text}}></h1>
        :
        <h2 className='dse-title__text' dangerouslySetInnerHTML={{__html: text}}></h2>
      }
    </section>
  )
}

export default Title
