import React, { useEffect, useState } from "react";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import Title from "../title/title";
import GlossaryItem from "../GlossaryItem/GlossaryItem";
import clsx from "clsx";

const Glossary = ({ className, glossaryList }) => {
  const [clientGlossaryList, setClientGlossaryList] = useState(glossaryList);

  useEffect(() => {
    // Ensure client and server data are the same
    setClientGlossaryList(glossaryList);
  }, [glossaryList]);

  let half1 = [];
  let half2 = [];

  clientGlossaryList.forEach((item, i) => {
    i < Math.ceil(clientGlossaryList.length / 2) ? half1.push(item) : half2.push(item);
  });

  const buildGlossaryList = (list) => {
    return list.map((item, i) => (
      <GlossaryItem
        id={item.id}
        key={i}
        title={item.title}
        audio={item.audio}
      >
        <p>{item.text}</p>
      </GlossaryItem>
    ));
  };

  return (
    <section className= { clsx("dse-glossary", className) }>
        <DSEWrapper className="dse-glossary__title" fullWidth={true}>
          <Title text="GLOSSARY"/>
        </DSEWrapper>
        <DSEWrapper>
        <div className="dse-glossary__list">
          <div className="dse-glossary__list-col">
            {buildGlossaryList(half1)}
          </div>
          <div className="dse-glossary__list-col">
            {buildGlossaryList(half2)}
          </div>
        </div>
        </DSEWrapper>
    </section>
  );
};

export default Glossary;