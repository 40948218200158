import React, { memo } from 'react'
import DSELayout from '../components/DSE/DSELayout/DSELayout';
import Glossary from '../components/DSE/Glossary/Glossary';
import FaqAccordion from '../components/FaqAccordion/FaqAccordion';

import epsteinAudio from '../assets/audios/BL-0004_Epstein-barr-virus_48k_stereo.mp3';
import headAndNeckAudio from '../assets/audios/BL-0005_Head-and-neck-cancer_48k_stereo.mp3';
import humanPapillomavirusAudio from '../assets/audios/BL-0006_Human-papillomavirus_48k_stereo.mp3';

const GlossaryList = [
  {
    id: 'glossary-epstein',
    title: 'Epstein-Barr virus (EBV)',
    audio: epsteinAudio,
    text: 'A common virus remaining inactive in most people. EBV is associated with certain cancers such as NPC, specifically types 2 and 3.'
  },
  {
    id: 'glossary-chemotherapy',
    title: 'Head and neck cancer',
    audio: headAndNeckAudio,
    text: 'Cancer that presents in the head or neck region (areas such as the nasal cavity, sinuses, throat, voice box, mouth, or salivary glands).'
  },
  {
    id: 'glossary-imrt',
    title: 'Human papillomavirus (HPV)',
    audio: humanPapillomavirusAudio,
    text: 'The most common sexually transmitted infection. HPV is associated with type 1 NPC.'
  }
];


const Faqs = props => {

  return (
    <React.Fragment>
      <DSELayout
        canonicalURL='https://npcfacts.com/frequently-asked-questions'
        title='FAQs about nasopharyngeal cancer (NPC)'
        pageTitle='FAQs about nasopharyngeal cancer (NPC)'
        description='Nasopharyngeal cancer: explore frequently asked questions'
        keyWords='Nasopharyngeal carcinoma; NPC treatments; NPC outcomes'
      >
        <FaqAccordion />
        <Glossary className={'dse-grad-bg-green-darker-green'} glossaryList={GlossaryList} />
      </DSELayout>
    </React.Fragment>
  );
}

export default memo(Faqs)
