import React, { useState, useEffect, useRef } from "react";

import audioIcon from "../../../assets/images/components/Glossary/audio-icon.svg";

const GlossaryItem = ({ id, audio, title, children, className = '' }) => {
  const [played, setPlayed ] = useState(false);
  const audioTag = useRef(null);

  const playHandler = () => {
    setPlayed(!played);
  };

  useEffect(() => {
    if(played === true) {
       audioTag.current.play() 
    } else {
      (audioTag.current.pause());
      audioTag.current.currentTime = 0;
    }
  }, [played])

  const audioEnd_Handler = () => {
    setPlayed(false);
  };

  return (
    <div className={`dse-glossary-item ${className}`} id={id}>
      <audio className="dse-glossary-item__audio" controls={false} ref={audioTag} onEnded={audioEnd_Handler}>
        <source src={audio} type="audio/mpeg"/>
      </audio>
      <div className={`dse-glossary-item__title ${played ? 'dse-glossary-item__title--highlight' : ''}`} onClick={playHandler} role='presentation'>
        <h3 dangerouslySetInnerHTML={{__html: title}}></h3>
        <img src={audioIcon} alt="audio icon" />
      </div>
      <div className="dse-glossary-item__text">
        {children}
      </div>
    </div>
  )
};

export default GlossaryItem;