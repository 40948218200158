import React from "react";
import arrowIcon from '../../../assets/images/global/icon-arrow.svg'

const DseCtaIcon = ({
  text,
  title,
  href = "",
  target = "_self",
  classes
}) => {
  return (
    <div className="dse-cta-container">
      <a
        className={`dse-cta-icon ${classes}`}
        href={href}
        aria-label={text}
        {...(title && { title: title })}
        target={target}
      >
        <img src={arrowIcon} alt='' />
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      </a>
    </div>
  );
};

export default DseCtaIcon;
