import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import DSEWrapper from "../DSE/DSEWrapper/DSEWrapper";
import Title from "../DSE/title/title";
import { Link } from "gatsby";
import linkIcon from "../../assets/images/global/link-icon.png";
import DseCtaIcon from "../DSE/DseCtaIcon/DseCtaIcon";

function FaqAccoridion() {
  const [activeKey, setActiveKey] = useState(null);

  const faqData = [
    {
      question: "Is nasopharyngeal cancer the same as head and neck cancer?",
      answer:
        "Nasopharyngeal cancer is a type of head and neck cancer. However, it is considered distinct from other types of head and neck cancer, with unique risk factors and subtypes.",
      link: "/about-nasopharyngeal-cancer/",
      linkText: "See more about the 3 types of nasopharyngeal cancer",
    },
    {
      question: "Is nasopharyngeal cancer common?",
      answer:
        "Nasopharyngeal cancer is considered a rare cancer in the United States. It occurs more frequently in other parts of the world, specifically Southeast Asia. Being of Southeast Asian descent is a risk factor for nasopharyngeal cancer.",
      link: "/risk-factors-and-symptoms/",
      linkText: "See more risk factors",
    },
    {
      question: "What causes nasopharyngeal cancer?",
      answer:
        "The exact cause of nasopharyngeal cancer is unknown. However, there are risk factors that have been associated with the development of nasopharyngeal cancer.",
      link: "/risk-factors-and-symptoms/",
      linkText: "Take a look at the ones that have been identified",
    },
    {
      question: "Where is nasopharyngeal cancer located?",
      answer:
        "Nasopharyngeal cancer starts in the nasopharynx, located in the upper part of the throat.",
      link: "/about-nasopharyngeal-cancer/",
      linkText: "See a diagram showing where the nasopharynx is located",
    },
    {
      question: "What are the symptoms of nasopharyngeal cancer?",
      answer:
        "Nasopharyngeal cancer can have many symptoms, including a lump or mass in the neck and hearing loss.",
      link: "/risk-factors-and-symptoms/",
      linkText: <>Read more about the possible signs&nbsp;of nasopharyngeal <br class='d-sm-none'/>cancer</>,
    },
    {
      question:
        "Is the Epstein-Barr virus associated with nasopharyngeal cancer?",
      answer:
        "The Epstein-Barr virus has been associated with certain types of nasopharyngeal cancer.",
      link: "/risk-factors-and-symptoms/",
      linkText: "See which types and explore other risk factors",
    },
    {
      question:
        "Is human papillomavirus (HPV) infection associated with nasopharyngeal cancer?",
      answer:
        "There is a possible association between HPV infection as a viral risk factor for type 1 nasopharyngeal cancer.",
      link: "/risk-factors-and-symptoms/",
      linkText: <>Take a look at other risk <br class='d-sm-none'/> factors</>,
    },
    {
      question: "How is nasopharyngeal cancer diagnosed?",
      answer:
        "Healthcare providers have different diagnostic tools to use in order to figure out if someone has nasopharyngeal cancer, including taking a medical history and a variety of tests and exams.",
      link: "/diagnosis-and-treatment/",
      linkText: "See the details about how a diagnosis is reached",
    },
    {
      question: "How is nasopharyngeal cancer treated?",
      answer:
        "Nasopharyngeal cancer can be treated in a variety of ways, and sometimes with a combination of treatments.",
      link: "/diagnosis-and-treatment/",
      linkText: "Read about the different treatment options",
    },
    {
      question: "Can nasopharyngeal cancer come back?",
      answer: "Nasopharyngeal cancer can come back after being treated.",
      link: "/about-nasopharyngeal-cancer/",
      linkText: "See the 2 main ways it can come back",
    },
  ];

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <section className="dse-faq-npc">
      <DSEWrapper className="dse-faq-npc__title" fullWidth={true}>
        <Title text="FREQUENTLY ASKED QUESTIONS" isH1={true} />
      </DSEWrapper>
      <DSEWrapper className="dse-faq-npc__accordion">
        <Accordion activeKey={activeKey} alwaysOpen={true}>
          {faqData.map((faq, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header onClick={() => handleToggle(index.toString())}>
                {faq.question}
                <span className={`toggle-icon ${activeKey !== index.toString() ? "" : "active"}`}>
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {faq.answer}
                <br />
                <Link to={faq.link}>
                  {faq.linkText}<img src={linkIcon} width="25" />
                </Link>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>

        <DseCtaIcon
          text="Get helpful resources, information,&nbsp;and&nbsp;support"
          href="/resources/"
        />
      </DSEWrapper>
    </section>
  );
}

export default FaqAccoridion;
